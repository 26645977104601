<template>
  <div class="space-y-4">
    <span class="text-headline">
      {{ $t("COMMON.CHOOSE") }}
      {{ $t("COMMON.BRANDING") }}
    </span>
    <div class="flex flex-wrap gap-4">
      <div class="flex flex-col space-y-2 w-36">
        <icon-base
          :icon="IconEmailBrandingPreview"
          width="90"
          height="64"
          class="rounded"
        />
        <label class="flex cursor-pointer space-x-2">
          <input
            type="radio"
            :checked="selectedBrandingId === null"
            @click="selectedBrandingId = null"
            class="cursor-pointer mt-0-25"
          />
          <span class="text-headline">
            {{ $t("COMMON.NO") }}
            {{ $t("COMMON.BRANDING").toLowerCase() }}
          </span>
        </label>
      </div>
      <email-branding
        v-for="template in brandings"
        :key="template.id"
        :template="template"
        v-model="selectedBrandingId"
        class="w-36"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import type { TestEmailData } from "@/models/emails";
import { type PropType } from "vue";
import IconBase from "@/components/ui/IconBase.vue";
import IconEmailBrandingPreview from "@/components/icons/IconEmailBrandingPreview.vue";
import EmailBranding from "@/views/deals/components/emails/EmailBranding.vue";
defineProps({
  brandings: {
    type: Array as PropType<TestEmailData["email_customizations"]>
  }
});

const selectedBrandingId = defineModel<number | null>();
</script>
